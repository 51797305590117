$(document).ready(function () {

	$('.alt_propimage').map(function (i, property) {
		$(property).find('a').append('<div style="background-image:url(\' ' + $(property).find('img').attr('src') + ' \');"></div>');
	});

	$('.subdivisionlist div, .subdivisionlist ol').hover(function () {
		// $('.subdivisionlist ol').toggle();
		$(this).parent('.subdivisionlist').find('ol').toggle();
	});

});

