//declear the global verable
var isAlreadyPopUp = false;

$(document).ready(function () {

	//Wrap navigation to more dropdown link
	$('#menu_section .menu ul').navwrap();

	/* show hide navigation */
	$(".menu_icon").click(function () {
		$(".main_menu.hidden").show();
		$("#menu_section").hide();
	});

	$(".close_icon").click(function () {
		$("#menu_section").show();
		$(".main_menu.hidden").hide();
	});

	if ($(".bidx_image_list ol").length > 0) {
		$(".property_img_gallery .bidx_image_list li").each(function (index) {
			$(this).attr('data-index', index);
		});
		$(".main-image .bidx_image_list li").each(function (index) {
			$(this).attr('data-index', index);
		});
		$(".property_img_gallery .bidx_image_list li").first().addClass('active');
		createCarousel();
	}


	function createCarousel() {
		//Property Image Thumbnails Carousel
		$(".property_img_gallery .bidx_image_list ol").carouFredSel({
			auto: false,
			circular: true,
			infinite: true,
			align: "left",
			width: "100%",
			height: 85,
			scroll: {
				items: 1,
				duration: 1000
			},
			prev: ".prev",
			next: ".next",
			onCreate: function (data) {
				localStorage.setItem("visibleThumbnails", data.items.length);
			}
		});

		// MAin Image Carousel
		$(".main-image .bidx_image_list ol").carouFredSel({
			circular: true,
			auto: false,
			infinite: true,
			align: "center",
			width: "100%",
			height: 541,
			responsive: true,
			items: 1,
			scroll: {
				items: 1,
				duration: 1000,
				onAfter: function (data) {
					var index = '';
					var visibleThumbnails = parseInt(localStorage.getItem("visibleThumbnails"));
					data.items.visible.each(function () {
						index = parseInt($(this).attr('data-index'));
					});
					var el = $('.property_img_gallery .bidx_image_list ol').find('li[data-index=' + index + ']');
					$('.property_img_gallery .bidx_image_list li').removeClass('active');
					el.addClass('active');

					if (visibleThumbnails <= index) {
						$(".property_img_gallery .bidx_image_list ol").trigger("slideTo", (index - visibleThumbnails) + 1);
					} else if (index == 0) {
						$(".property_img_gallery .bidx_image_list ol").trigger("slideTo", index);
					}
				}
			},
			prev: "#prev",
			next: "#next",
			onCreate: onCreate
		});
	}

	$(".property_img_gallery li.bidx_image_list").click(function (e) {
		var i = $(this).attr('data-index');
		$('.property_img_gallery .bidx_image_list li').removeClass('active');
		$(this).addClass('active');
		$(".main-image .bidx_image_list ol").trigger("slideTo", $(".main-image .bidx_image_list li[data-index=" + i + "]"));
	});


	//Mobile navigation
	var $cloneNav = $("#menu_section .menu li").clone();

	$("header").prepend('<ul class="mobile-nav"></ul>');
	$cloneNav.appendTo(".mobile-nav");
	$('.mobile-nav').slicknav();

	//to clone detail page comments
	var detailComment = $(".detail_comment").clone();
	var detailCommentprint = $(".detail_comment").clone();
	$(".detail_comment").remove();
	var insertAfterEl = $("#detailprop ul.col2");

	$(detailComment).insertAfter(insertAfterEl);
	$(detailCommentprint).insertAfter($("#detailprop")).addClass("detailCommentprint");

	var commentText = $(detailCommentprint).find("li").text();
	if (commentText.length > 900) {
		commentText = commentText.slice(0, 900) + " ... ";
		$(detailCommentprint).find("li").text(commentText);
	}

	//to add notes heading on flyer
	$(".notes-print").prepend("<h2 class='notes'>Contact Info</h2>");


	if ($(".testimonial-list-container").length > 0) {
		$(".testimonial-list-container .testimonial").show();
		$(".testimonial-list-container").carouFredSel({
			circular: true,
			infinite: false,
			align: "left",
			height: 130,
			responsive: true,
			scroll: {
				items: 1,
				duration: 1000
			},
			pagination: "#foo2_pag"
		});
	}

	/* Similar Properties Slider */
	if ($(".similar-properties-wrap").length > 0) {
		if ($(window).width() < 480) {
			var sliditem = 1;
		} else if ($(window).width() < 1024) {
			var sliditem = 2;
		} else {
			var sliditem = 2;
		}
		$(".similar-properties-wrap .similar-properties-slider").carouFredSel({
			circular: true,
			infinite: true,
			align: "center",
			responsive: true,
			width: "95%",
			items: sliditem,
			scroll: {
				items: 1,
				duration: 1000,
				pauseOnHover: true
			},
			prev: ".prev-similar",
			next: ".next-similar",
		});
	}

	// For floating form..
	if ($('#detailform').length > 0) {
		var start = ($('#detailform').position().top) + ($('#detailform').height());
		$(".property-detail-sec").css({"min-height": $('#detailform').height() + 88});
		$(window).scroll(function () {
			var stops = ($('body').height()) - (($('footer').height()) + ($('#detailform').height()) + 88);
			var windowpos = $(window).scrollTop();
			if (windowpos >= start) {
				$("#detailform").addClass("scrolled");
			} else {
				$("#detailform").removeClass("scrolled");
			}
			if (windowpos >= stops) {
				$("#detailform").removeClass("scrolled");
			}
		});
	}

	//For seller popup form on scroll detail page...
	/*	if($('.seller-popup-form-widget').length > 0){
	 $(window).scroll(function() {
	 var windowpos = $(window).scrollTop();
	 if(!isAlreadyPopUp){
	 if (windowpos >= 100) {
	 $( ".seller-popup-form-widget" ).dialog( "open");
	 isAlreadyPopUp = true;
	 }
	 }
	 });
	 }
	 */
	$('.seller-popup-form').click(function () {

		$(".seller-popup-form-widget").dialog("open");

	});

});

/* for homepage banner search form */
if ($('.banner-searchbar').length > 0) {
	/* Declare address array for homepage banner script */
	var componentForm = {
		banner_street_number: {street_number: 'long_name'},
		banner_street_name: {route: 'long_name'},
		banner_area: {sublocality_level_1: 'long_name'},
		banner_city: {locality: 'long_name'},
		banner_postal_code: {postal_code: 'short_name'},
	}

	/* For banner search bar places */
	if (typeof google === 'object' && typeof google.maps === 'object') {
		google.maps.event.addDomListener(window, 'load', function () {
			var places = {};
			places = new google.maps.places.Autocomplete(document.getElementById('homepage_search_txtPlaces'));
			places.setComponentRestrictions({'country': ['us']});
		});
	}
}

function onCreate() {
	// Get all the possible height values from the slides
	var $carousel = $(".main-image .bidx_image_list ol");
	var heights = $carousel.children().find('.main-item').find('.valign').map(function () {
		return $(this).height();
	});
	// Find the max height and set it
	$carousel.parent().add($carousel).height(Math.max.apply(null, heights));
	$carousel.find('.parent').height(Math.max.apply(null, heights));
}

function banner_header_slide() {
	var lastClass = "";
	var newClass = "";
	var header_lastClass = $('#homepage-banner').attr('class').split(' ');
	if (header_lastClass) {
		if (jQuery.inArray('type_buyer', header_lastClass) !== -1) {
			var lastClass = "type_buyer";
			var newClass = "type_seller";
		} else if (jQuery.inArray('type_seller', header_lastClass) !== -1) {
			var lastClass = "type_seller";
			var newClass = "type_preapproved";
		} else if (jQuery.inArray('type_preapproved', header_lastClass) !== -1) {
			var lastClass = "type_preapproved";
			var newClass = "type_buyer";
		}
	}
	$("#homepage-banner").removeClass(lastClass);
	$("#homepage-banner").addClass(newClass);
}

